(function () {
	'use strict';

	angular
		.module('activation')
		.factory('subscriptions', ['$http', 'utilities', subscriptions]);

	function subscriptions($http, utilities) {
		return {};
	}
})();
